import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/about.jpg"
import manage from "../../img/CON1.jpg"
import it from "../../img/CON2.jpg"
import Footer from '../../Components/Footer/Footer'
export default function Consulting() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Consulting</h1>
                            <p href="" class="h5 text-white">"Expert Consulting Services for Your Business Success."</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">“Welcome to Inventech, where we specialize in providing top-notch consulting solutions to help businesses thrive in today's dynamic market. Our team of experienced consultants is dedicated to understanding your unique challenges and crafting tailored strategies for your success.”
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Why Choose Us</h1>
                            </div>
                            <p className="mb-4"><h5>Industry Expertise:</h5> With years of experience across various industries, we bring in-depth knowledge and insights to every project we undertake.
                            </p>
                            <p className="mb-4">
                                <h5>Customized Approach:</h5> We believe in a one-size-fits-one approach. Our solutions are customized to address your specific needs, ensuring tangible results.
                            </p>
                            <p className="mb-4">
                                <h5> Proven Track Record:</h5> Our track record of delivering measurable results speaks for itself. We've helped businesses of all sizes achieve their goals and exceed their expectations.
                            </p>
                            <p className="mb-4">
                                <h5>Collaborative Partnership:</h5> We consider ourselves an extension of your team. We collaborate closely with you to develop strategies that align with your vision and objectives.
                            </p>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={manage} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={it} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Our Services</h1>
                            </div>
                            <p className="mb-4">  <h5>Strategic Planning:</h5> Leverage our strategic expertise to map out a clear path for your business growth. We identify opportunities, set objectives, and guide you towards success.
                            </p>
                            <p className="mb-4">   <h5> Market Analysis:</h5> Stay ahead of the competition with our comprehensive market analysis. Understand trends, customer behavior, and emerging opportunities.
                            </p>
                            <p className="mb-4">    <h5>Operational Efficiency:</h5> Enhance your operational efficiency with our process optimization strategies. Streamline your workflows for better productivity and cost savings.
                            </p>
                            <p className="mb-4">    <h5>Change Management:</h5> Successfully navigate transitions and changes within your organization. Our change management strategies ensure a smooth transformation.
                            </p>
                            <p className="mb-4">    <h5>Digital Transformation:</h5> Embrace the digital age with confidence. We guide you through the process of adopting new technologies and staying relevant in a digital world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
