import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home';
import Cyber_security from './Pages/Cyber_security/Cyber_security';
import Ui_ux from './Pages/Ui_ux/Ui_ux';
import Testing_qa from './Pages/Testing_qa/Testing_qa';
import InfrastructureMgt from './Pages/InfrastructureMgt/InfrastructureMgt';
import DataAnalytics from './Pages/DataAnalytics/DataAnalytics';
import Itsupport from './Pages/Itsupport/Itsupport';
import Staffingsolution from './Pages/Staffingsolution/Staffingsolution';
import Talentsolution from './Pages/Talentsolution/Talentsolution';
import About from './Pages/About/About';
import Consulting from './Pages/Consulting/Consulting';
import Solutions from './Pages/Solutions/Solutions';
import Softwaredevelopment from './Pages/Softwaredevelopment/Softwaredevelopment';
import Itservices from './Pages/Itservices/Itservices';
import Crm from './Pages/CRM/Crm.jsx';
import Salesforce from './Pages/Salesforce/Salesforce';
import Services from './Pages/Services/Services';
import Blog from './Pages/Blog/Blog';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/testingqa" element={<Testing_qa />} />
      <Route path="/ui-ux" element={<Ui_ux />} />
      <Route path="/cybersecurity" element={<Cyber_security />} />
      <Route path="/infrastructuremanagement" element={<InfrastructureMgt />} />
      <Route path="/crm" element={<Crm />} />
      <Route path="/dataanalytics" element={<DataAnalytics />} />
      <Route path="/itsupport" element={<Itsupport />} />
      <Route path="/consulting" element={<Consulting />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/about" element={<About />} />
      <Route path="/softwaredevelopment" element={<Softwaredevelopment />} />
      <Route path="/itservices" element={<Itservices />} />
      <Route path="/salesforce" element={<Salesforce />} />
      <Route path="/services" element={<Services />} />
      <Route path="/blog" element={<Blog />} />

       {/* <Route path="/staffingsolution" element={<Staffingsolution />} /> */}
      {/* <Route path="/talentsolution" element={<Talentsolution />} /> */}
      {/* <Route path="/contact" element={<Home />} /> */}
    </Routes>
  );
}

export default App;
