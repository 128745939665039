import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/support.jpeg"
import Footer from '../../Components/Footer/Footer'

export default function Itsupport() {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })

    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">IT Support</h1>
                            <p href="" class="h5 text-white">"IT support ensures tech issues are swiftly resolved."</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">The goal as a company is to have customer service that is not just the best but legendary.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={about} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">IT Support</h1>
                            </div>
                            <p className="mb-4">
                                The IT functions with the greatest potential for successfully reducing costs through outsourcing are help desk support, desktop support and network operations</p>
                            <p className="mb-4">
                                Your IT person or team is probably doing a lot of unnoticed work just to keep your IT infrastructure up and running. What if you could take those tasks off your IT team's plate and allow them to dedicate more time to strategic business initiatives?
                            </p>
                            <p className="mb-4">
                                With more and more businesses moving towards remote employees and utilizing mobile technology, you need to ensure your workers are connected no matter where they are. However, with an increase in mobile workers comes new security, privacy and usage challenges. With our IT User Support Services, we can proactively manage your infrastructure with real time alerts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
