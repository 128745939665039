import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import "./Header.css"
import logo from "../../img/inventechlogo.png"

export default function Header(props) {
  console.log(props.fix);
  let location = useLocation();
  const [home, setHome] = useState()
  const [about, setAbout] = useState()
  const [testing, setTesting] = useState()
  const [solutions, setSolutions] = useState()
  const [consulting, setConsulting] = useState()
  const [blog, setBlog] = useState()


  useEffect(() => {
    if (location.pathname === "/") {
      setHome("active");

    } else if (location.pathname === "/about") {
      setAbout("active");
    } else if (location.pathname === "/testingqa" ||
      location.pathname === "/ui-ux" ||
      location.pathname === "/cybersecurity" ||
      location.pathname === "/infrastructuremanagement" ||
      location.pathname === "/dataanalytics" ||
      location.pathname === "/itsupport" ||
      location.pathname === "/softwaredevelopment" ||
      location.pathname === "/crm" ||
      location.pathname === "/salesforce" ||
      location.pathname === "/services" ||
      location.pathname === "/itservices"
    ) {
      setTesting("active");
    } else if (location.pathname === "/solutions") {
      setSolutions("active");
    } else if (location.pathname === "/consulting") {
      setConsulting("active");
    }else if (location.pathname === "/blog") {
      setBlog("active");
    }
  }, [])
  return (
    <>
      <div className={` ${props.fix === true ? "position-fixed" : "position-relative"} container-fluid  p-0`}>
        <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
          <Link to="/" className="navbar-brand p-0">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div>
                <img src={logo} alt="logo" className='inv-logo' />
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <h1 className="m-0">
                  Inventech</h1>
              </div>
            </div>

          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="fa fa-bars"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className={`${home} nav-item nav-link `}>Home</Link>
              <div className="nav-item dropdown">
                <a href="#" className={`${testing} nav-link dropdown-toggle `} data-bs-toggle="dropdown">Services</a>
                <div className="dropdown-menu dropdown-menu-right m-0 dropper-content">
                  <div className='dropper'>
                    <div className='widther'>
                      <Link to="/services" class="dropdown-header">Services </Link>
                      <p style={{ padding: " 0.25rem 1rem", marginBottom: "0" }}>Our service portfolio covers an entire software development life cycle and meets varied business needs. </p>
                      <p style={{ padding: " 0.5rem 1rem", color: "#124ebc", marginBottom: "0" }} >Ask us</p>
                    </div>
                    <div>
                      <Link to="/softwaredevelopment"><h6 class="dropdown-header">Software Development </h6></Link>
                      <Link to="/testingqa" className="dropdown-item">Testing QA</Link>
                      <Link to="/ui-ux" className="dropdown-item">UI - UX</Link>
                      <Link to="/cybersecurity" className="dropdown-item">Cyber Security</Link>
                      <Link to="/crm" className="dropdown-item">CRM</Link>
                    </div>
                    <div>
                      <Link to="/itservices"><h6 class="dropdown-header">Managed IT Services </h6></Link>
                      <Link to="/infrastructuremanagement" className="dropdown-item">Infrastructure Management</Link>
                      <Link to="/dataanalytics" className="dropdown-item">Data Analytics</Link>
                      <Link to="/itsupport" className="dropdown-item">IT Support</Link>
                      <Link to="/salesforce" className="dropdown-item">Sales Force</Link>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/solutions" className={` ${solutions} nav-item nav-link`}>Solutions</Link>
              {/* <div className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Techonogies</a>
                <div className="dropdown-menu m-0">
                  <Link to="/staffingsolution" className="dropdown-item">Staffing Solution</Link>
                  <Link to="/talentsolution" className="dropdown-item">Talent Solution</Link>
                </div>
              </div> */}
              <Link to="/consulting" className={` ${consulting} nav-item nav-link`}>Consulting</Link>
              <Link to="/blog" className={` ${blog} nav-item nav-link`}>Blog</Link>
              <Link to="/about" className={`${about} nav-item nav-link `}>About</Link>

            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
