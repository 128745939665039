import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/about.jpg"
import manage from "../../img/cyber.png"
import it from "../../img/crm.jpg"
import Footer from '../../Components/Footer/Footer'

export default function Crm() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">CRM</h1>
                            <p href="" class="h5 text-white">"InvenTech is an end-to-end provider of Salesforce services, including design, consulting, implementation, and support."</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">“Yes, CRM is all about Customer Relationship Management… but it is also about prospect relationships.” <br />― Bobby Darnell
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={it} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">CRM</h1>
                            </div>
                            <p className="mb-4">
                                IntecTech delivers Salesforce customer relationship management (CRM) and customer experience management (CEM) solutions through sales, service, and marketing cloud in addition to custom platform-as-a-service (PaaS) applications that provide the foundation for sustainable growth and increased success.
                            </p>
                            <p className="mb-4">
                                Our in-depth processes and application knowledge, combined with best practices, enable us to offer Salesforce services, including consultancy, implementation, and support.
                                This enables our clients to identify, design and deliver digitally enabled and market leading products, services & experiences.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-lg-12">
                            <div class="mb-5">
                                <p className="mb-4">
                                    Our designers, technology experts and industry SMEs represent the top 3 pool of Salesforce certified consultants and have helped us create a customer-centric organization.
                                </p >
                                <p className="mb-4">
                                    InvenTech works across the Salesforce platform and products to drive innovative and transformational outcomes. We are one of the fastest growing Salesforce consulting practices globally, delivering unprecedented value to our customers
                                </p>


                                <p> <i className="fa fa-check text-primary me-3"></i>
                                    Global strategic partner, the InvenTech Salesforce Business Group (SFBG) is THE trusted leader in designing, implementing, and driving transformational experiences with Salesforce solutions.

                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>
                                    Bringing sustainability to the front office to provide leaders with true visibility into their company’s environmental, social and governance data.

                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Strong domain expertise across all industry and technology sectors, including Cloud and Social networking
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Expertise in enterprise CRM and non-CRM applications on Force.com leveraging Apex Code, VisualForce and other technologies
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
