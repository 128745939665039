import React, { useEffect } from 'react'
import carosal1 from "../../img/carousel-1.jpg"
import carosal2 from "../../img/carousel-2.jpg"
import Header from '../../Components/Header/Header'
import about from "../../img/about.jpg"
import sdl from "../../img/sdl.png"
import home1 from "../../img/home1.png"
import feature from "../../img/feature.jpg"
import team1 from "../../img/team-1.jpg"
import team2 from "../../img/team-2.jpg"
import team3 from "../../img/team-3.jpg"
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import tunnel from "../../Video/tunnel_-_84938 (720p).mp4"
import scrolling from "../../Video/bokeh_-_15493 (720p).mp4"
import UI from "../../Video/technology_-_124333 (Original).mp4"
import digital from "../../Video/digital_-_45056 (720p).mp4"

import "./Home.css"
export default function Home() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })


        const f = "racecar";
        const d = f.split("")
        console.log(d);
    })


    return (<>
        <div id="spinner" className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div className="spinner"></div>
        </div>
        <Header />


        <div className="container-fluid position-relative p-0">
            <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active Home  p-0 w-100" data-bs-interval="5000">
                        <video width="100vw" height="110vh" autoPlay loop muted playsInline controls={false} className="home--video">
                            <source src={scrolling} type="video/mp4" />
                        </video>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: "900px" }}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Application Development</h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">We build custom applications that solve your business challenges and delight your users"</h1>
                                {/* <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a> */}
                                {/* <Link to="/about" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">About Us</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item Home  p-0 w-100" data-bs-interval="5000">
                        <video width="100vw" height="110vh" autoPlay loop muted playsInline controls={false} className="home--video">
                            <source src={UI} type="video/mp4" />
                        </video>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center carousel-caption-d">
                            <div className="p-3" style={{ maxWidth: " 900px" }}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Product Development</h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">We create products that enhance your lifestyle and empower you with data and insights"</h1>
                                {/* <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a> */}
                                {/* <Link to="/about" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">About Us</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item Home  p-0 w-100" data-bs-interval="5000">
                        <video width="100vw" height="110vh" autoPlay loop muted playsInline controls={false} className="home--video">
                            <source src={tunnel} type="video/mp4" />
                        </video>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{ maxWidth: " 900px" }}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Talent acquisition</h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">We recruit talent that matches your vision and values"</h1>
                                {/* <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a> */}
                                {/* <Link to="/about" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">About Us</Link> */}
                            </div>
                        </div>
                    </div>



                    <div className="carousel-item Home  p-0 w-100" data-bs-interval="5000">
                        <video width="100vw" height="110vh" autoPlay loop muted playsInline controls={false} className="home--video">
                            <source src={digital} type="video/mp4" />
                        </video>
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center carousel-caption-d">
                            <div className="p-3" style={{ maxWidth: " 1000px" }}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">Sales Force</h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">We implement Salesforce to streamline your sales processes and boost your results"</h1>
                                {/* <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Free Quote</a> */}
                                {/* <Link to="/about" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">About Us</Link> */}
                            </div>
                        </div>
                    </div>

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

        <div className="modal fade" id="searchModal" tabindex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content" style={{ background: "rgba(9, 30, 62, .7)" }}>
                    <div className="modal-header border-0">
                        <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body d-flex align-items-center justify-content-center">
                        <div className="input-group" style={{ maxWidth: " 600px" }}>
                            <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                            <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className="container-fluid facts py-5 pt-lg-0">
            <div className="container py-5 pt-lg-0">
                <div className="row gx-0">
                    <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                        <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: "150px" }}>
                            <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: "60px", height: "60px" }}>
                                <i className="fa fa-users text-primary"></i>
                            </div>
                            <div className="ps-4">
                                <h5 className="text-white mb-0">Happy Clients</h5>
                                <h1 className="text-white mb-0" data-toggle="counter-up">12345</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                        <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{ height: "150px" }}>
                            <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{ width: "60px", height: "60px" }}>
                                <i className="fa fa-check text-white"></i>
                            </div>
                            <div className="ps-4">
                                <h5 className="text-primary mb-0">Projects Done</h5>
                                <h1 className="mb-0" data-toggle="counter-up">12345</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                        <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: "150px" }}>
                            <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: "60px", height: "60px" }}>
                                <i className="fa fa-award text-primary"></i>
                            </div>
                            <div className="ps-4">
                                <h5 className="text-white mb-0">Win Awards</h5>
                                <h1 className="text-white mb-0" data-toggle="counter-up">12345</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}


        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-7">
                        <div className="section-title position-relative pb-3 mb-5">
                            <h5 className="fw-bold text-primary text-uppercase">Who we are</h5>
                            <h1 className="mb-0">“Technology is best when it brings people together.”</h1>
                        </div>
                        <p>We are a 12 year old information technology firm with an established record of delivering IT projects on time and within budget.</p>
                        <p className="mb-4">InvenTech Solutions supports a wide array of Fortune companies as well as small to medium size businesses.
                            Our long-term growth and accompanying expansion of our service offering have remained based on our original cornerstone: i.e. to deliver expertise through right solution for client by accurate business assessment consulting and thus help our clients optimize their Business need & IT initiatives</p>
                        <div className="row g-0 mb-3">
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Award Winning</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Professional Staff</h5>
                            </div>
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>24/7 Support</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Fair Prices</h5>
                            </div>
                        </div>
                        
                        <Link to="/about" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Explore</Link>
                    </div>
                    <div className="col-lg-5" style={{ minHeight: "500px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={about} style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                    <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
                    <h1 className="mb-0">We Are Here to Grow Your Business Exponentially</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4">
                        <div className="row g-5">
                            <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                                <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
                                    <i className="fa fa-cubes text-white"></i>
                                </div>
                                <h4>Best In Industry</h4>
                                <p className="mb-0">"Our company consistently delivers unparalleled quality and groundbreaking innovation."</p>
                            </div>
                            <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                                <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
                                    <i className="fa fa-award text-white"></i>
                                </div>
                                <h4>Award Winning</h4>
                                <p className="mb-0">"We are honored to be recognized as industry leaders with multiple award-winning achievements."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{ minheight: "350px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src={feature} style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="row g-5">
                            <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                                <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
                                    <i className="fa fa-users-cog text-white"></i>
                                </div>
                                <h4>Professional Staff</h4>
                                <p className="mb-0">"Our organization takes pride in maintaining a team of highly skilled and professional staff members."</p>
                            </div>
                            <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: "60px", height: "60px" }}>
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <h4>24/7 Support</h4>
                                <p className="mb-0">"We provide unparalleled 24/7 support, ensuring assistance whenever you need."</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                    <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                    <h1 className="mb-0">Our service portfolio covers an entire software development life cycle and meets varied business needs.</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fa fa-shield-alt text-white"></i>
                            </div>
                            <h4 className="mb-3">IT Outsourcing</h4>
                            <p className="m-0">Planning, Designing, Defining, building, Testing, Deployment, Maintenance</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fa fa-chart-pie text-white"></i>
                            </div>
                            <h4 className="mb-3">Data Analytics</h4>
                            <p className="m-0">Data Aggregation, Data Engineering, Business Intelligence & Reporting, Contextual, Intelligence, Advanced Analytics, Marketing, Analytics</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fa fa-code text-white"></i>
                            </div>
                            <h4 className="mb-3">Software Development</h4>
                            <p className="m-0">Customization, Product Development, Feature Development, Software maintenance Support & Maintenance</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fab fa-android text-white"></i>
                            </div>
                            <h4 className="mb-3">UI & UX</h4>
                            <p className="m-0">UI services Mobile App Web Design Consulting</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fa fa-search text-white"></i>
                            </div>
                            <h4 className="mb-3">Cyber security</h4>
                            <p className="m-0">Web Application, Product, Network, Operational, Physical, Security Code, Review</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                        <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="service-icon">
                                <i className="fab fa-android text-white"></i>
                            </div>
                            <h4 className="mb-3">QA & testing</h4>
                            <p className="m-0">Functional, Compatibility, Localization, Performance, Usability, Accessibility, Security</p>
                            {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5" >
                    <div className="col-lg-6" >
                        <div className=" position-relative pb-3 mb-2" >
                            <h5 className="fw-bold text-primary text-uppercase">SOFTWARE DEVELOPMENT LIFECYCLE OF IT OUTSOURCING TO INVENTECH</h5>
                        </div>
                        <p className="mb-3" >
                            We employ best practices and development methodologies for building effective software development processes and solutions in a structured and systematic way.
                        </p>
                        <div className="row g-0 mb-3">
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Analysis</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Development </h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i> Deployment</h5>

                            </div>
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Designing </h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Testing</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Maintainance</h5>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={sdl} style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                    <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
                    <h1 className="mb-0">Professional Stuffs Ready to Help Your Business</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src={team1} alt="" />
                                <div className="team-social">
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                </div>
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Full Name</h4>
                                <p className="text-uppercase m-0">Designation</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src={team2} alt="" />
                                <div className="team-social">
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                </div>
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Full Name</h4>
                                <p className="text-uppercase m-0">Designation</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                        <div className="team-item bg-light rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid w-100" src={team3} alt="" />
                                <div className="team-social">
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                </div>
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">Full Name</h4>
                                <p className="text-uppercase m-0">Designation</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <Footer />
    </>

    )
}
