import React, { useEffect } from 'react'
import aboutImg from '../../img/about.jpg'
import Footer from "../../Components/Footer/Footer";
import team1 from "../../img/team-1.jpg"
import team2 from "../../img/team-2.jpg"
import team3 from "../../img/team-3.jpg"
import Header from "../../Components/Header/Header";
import { Link } from "react-router-dom";
import about from "../../img/about.jpg"
export default function Itservices() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Manages Services</h1>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">Our complete managed IT services portfolio is available to help mange your IT so you can manage the rest of your business.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-lg-6">
                            <div class="mb-5">
                                <h1 class="mb-4">
                                    Managed Services</h1>
                                <p> <i className="fa fa-check text-primary me-3"></i>
                                    Maintenance and Support: Various Support models such as On-call, 24x5 and 24x7 support and monitoring services and much more
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>
                                    Support Development: Code maintenance, Customization, Retrofitting and Project Consolidation and Migrations... much more
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Patch Management Services: Planning Patch framework, Reduced time to apply multiple patches, reduced TOC and much more
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Upgrade Services: People Tools and Application upgrades using upgrade accelerators in conjunction with traditional PeopleSoft upgrade methods
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Health and Performance check: Performance reviews and recommendations
                                </p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Shared Infrastructure Services: Database maintenance, Third Party installation, configuration and administration, Win/UNIX system administration and network maintenance and administration
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                    key Differentiar
                                </h5>
                            </div>

                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Faster upgrade cycle with greater visibility into the upgrade process
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Center of Excellence for PeopleSoft and related domain areas
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Time and cost-effective service delivery model that help organizations achieve business goals effectively
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Proven solution frameworks and process assets
                            </p>
                            <p> <i className="fa fa-check text-primary me-3 mb-4"></i>
                                Integrated Helpdesk (Infrastructure and Applications)
                            </p>

                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                    Bussiness Values
                                </h5>

                            </div>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Reduction of TCO tools and accelerators
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Reduction in time to upgrade by using InvenTech's Upgrade Accelerators
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                End-to-end PeopleSoft Delivery under one roof
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Rich Talent Pool of Domain and technical expertise
                            </p>
                            <p> <i className="fa fa-check text-primary me-3"></i>
                                Reduction in risk by leveraging SLA-based 24x7x365 onsite-offshore support
                            </p>
                         
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
            <a
                href="#"
                class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
            >
                <i class="bi bi-arrow-up"></i>
            </a>
        </>
    )
}
