import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/cyber.jpg"
import Footer from '../../Components/Footer/Footer'
export default function Cyber_security() {
  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
})
  return (
    <>
      <Header />

      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Cyber Security</h1>
              <p href="" class="h5 text-white">"Cybersecurity safeguards digital assets from evolving online threats."</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
            <div className="col-lg-10">
              <div className=" position-relative pb-3 mb-5">
                <h1 className="mb-0">“Cyber-Security is much more than a matter of IT.”
                  <br />― Stephane Nappo</h1>
              </div>
              <p className="mb-4">Cyber security
                InvenTech Security provides next-generation cyber security consulting services to help your organization build cyber resilience by all means.</p>
            </div>
          </div>
        </div>
      </div>
     


      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h1 className="mb-0">"Our Cyber security Services & Capabilities"</h1>
              </div>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Threat Detection and Prevention</h5>
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Vulnerability Assessment</h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Intrusion Detection and Response </h5>
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Data Encryption </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Firewalls and Network Security  </h5>
                  <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Regular Backups  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={about} style={{ objectFit: "cover" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

    </>
  )
}
