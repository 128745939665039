/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import aboutImg from '../../img/about.jpg'
import Footer from "../../Components/Footer/Footer";
// import team1 from "../../img/team-1.jpg"
// import team2 from "../../img/team-2.jpg"
// import team3 from "../../img/team-3.jpg"
import Header from "../../Components/Header/Header";
import { Link } from "react-router-dom";
import VISION from "../../img/VISION.jpg"
import MISSION from "../../img/MISSION.jpg"
import "./About.css"
import { Button, Modal } from 'antd';

export default function About() {

  const [bangaloreModal, setBangaloreModal] = useState(false)
  const [hyderabadModal, setHyderabadModal] = useState(false)
  const [usaModal, setUsaModal] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  const handleBang = () => {
    setBangaloreModal(true)
  }

  const handleHyd = () => {
    setHyderabadModal(true)
  }

  const handleusa = () => {
    setUsaModal(true)
  }


  return (
    <>
      <Header />
      
      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">About Us</h1>
              <Link to="/" class="h5 text-white">
                Home
              </Link>
              <i class="far fa-circle text-white px-2"></i>
              <a href="" class="h5 text-white">
                About
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">
                  About Us
                </h5>
                <h1 className="mb-0">
                  We are a 12 year old information technology firm with an established record of delivering IT projects on time and within budget.
                </h1>
              </div>
              <p className="mb-4">
                We staff our projects and work force with skilled full-time employees that can be profiled as consummate IT professionals.  Our average employee can be summarized as:
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Average Age – 30 Years Old
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    5 –10 years of I.T. Experience
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Varied Business Settings
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>
                    Consummate Leaders
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Loyal/Long Term Employees
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Diverse Technical Backgrounds
                  </h5>
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-3"></i>Accomplished I.T. Professionals
                  </h5>
                </div>
              </div>
              <div
                className="d-flex align-items-center mb-4 wow fadeIn"
                data-wow-delay="0.6s"
              >
                <div class="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: "60px", height: "60px", }}>
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ps-4">
                  <h5 class="mb-2">Call to ask any question</h5>
                  <h4 class="text-primary mb-0">+91 80-26535959</h4>
                </div>
              </div>
              {/* <a
                href="quote.html"
                className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                data-wow-delay="0.9s"
              >
                Request A Quote
              </a> */}
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src={aboutImg}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">Our Vision</h5>
                <h1 className="mb-0">"To be the leading global provider of best of breed business solutions, leveraging technology, and delivered by Best Talent"</h1>
              </div>
              <p className="mb-2">
                Our vision is to redefine industry standards and inspire innovation. We envision a future where technology seamlessly integrates with human potential to create unparalleled solutions. Our commitment is to be at the forefront of this transformation, leveraging cutting-edge research and development to shape tomorrow's world.
              </p>
              <p className="mb-4">
                We aspire to be more than just a company; we aim to be a driving force for positive change. Our vision encompasses sustainability, inclusivity, and ethical practices, ensuring that our impact benefits not only our stakeholders but also society as a whole. Through unwavering dedication and a relentless pursuit of excellence, we will turn this vision into reality, making a lasting mark on the world.</p>
            </div>
            <div className="col-lg-6" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={VISION} style={{ objectFit: "cover" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6" style={{ minHeight: "500px" }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={MISSION} style={{ objectFit: "cover" }} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title position-relative pb-3 mb-5">
                <h5 className="fw-bold text-primary text-uppercase">Our Mission</h5>
                <h1 className="mb-0">"To be the leading global provider of best of breed business solutions, leveraging technology, and delivered by Best Talent"</h1>
              </div>
              <p className="mb-2"> Our mission is clear: to empower individuals and businesses with innovative solutions that catalyze growth and positive change. We believe in harnessing technology to create a more connected and sustainable world. Our commitment to this mission drives every aspect of our operations.
              </p>
              <p className="mb-2">We're dedicated to delivering top-notch products and services that not only meet but exceed customer expectations. Integrity, transparency, and excellence guide our daily decisions and actions. Furthermore, we're passionate about giving back to our communities and reducing our environmental footprint, aligning our mission with social responsibility.</p>
              <p className="mb-4">
                Our journey is a testament to our unwavering dedication to making a meaningful impact on the world, one customer and one innovation at a time.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div
            className="section-title text-center position-relative pb-3 mb-5 mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="fw-bold text-primary text-uppercase">
              Global Locations
            </h5>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s" >
              <div className="location-background" onClick={handleBang}>
                <div className="city-1 city" >
                  <p className="mb-0">Bangalore</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s" onClick={handleHyd}>
              <div className="location-background">
                <div className="city-2 city" >
                  <p className="mb-0">Hyderabad</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s" onClick={handleusa}>
              <div className="location-background">
                <div className="city-3 city" >
                  <p className="mb-0">USA</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6" style={{ minHeight: "500px", }}>
              <div className=" position-relative pb-3 mb-5">
                <h1 > WHY INVENTECH</h1>
                <p className="mb-2">
                  We bring in a lot of experience in serving clients from various industries like Retail, Manufacturing, Banking, Oil & Gas, BSFI, Government, Telecom, Healthcare etc. We have significant expertise and insight into the functioning of these industries.
                </p>
                <p className="mb-2">
                  We deliver our solutions that are met on-time, within budget, and with high quality; greater efficiency and responsive to your business requirement.
                </p>
                <p className="mb-2">
                  InvenTech Solutions a Bangalore based firm, providing Professional Services specializing in Information Technology. Established in 2010, InvenTech Solutions has had tremendous success with a wide range of clients and a diverse mix of consultative service.
                </p>
                <p className="mb-4">
                  Whether it is to design an award-winning E-commerce application or provide a "best practice" based management team to reduce costs and improve your IT performance, InvenTech Solutions is recognized as a leader by business professionals
                </p>
                <p className="mb-2">
                  The founding members have been in the information processing business for over 20 years and have had successful careers both within and outside of consulting.
                </p>
                <p className="mb-4">
                  The objective of our business is to provide a quality service to our customers; to that end we follow a “customer first” premise. This premise allows us to deliver
                </p>
                <div className="row g-0 mb-3">
                  <div className="col-sm-12 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3"></i>
                      Quality through experienced professionals
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3"></i>
                      The right fit the first time
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3"></i>
                      Project completion on time and within budget
                    </h5>

                  </div>

                </div>
                <h1 > COMPANY HISTORY </h1>
                <p className="mb-2">
                  Headquartered in Bangalore, Karnataka, with offices in Hyderabad, USA & Canada, InvenTech Solutions supports a wide array of Fortune 500 and 1,000 companies as well as small to medium size businesses.  Our long-term growth and accompanying expansion of our service offering have remained based on our original cornerstone: i.e. to deliver expertise through network management, web development, targeted staffing and healthcare consulting and thus help our clients optimize their IT initiatives and budgets.
                </p>
              </div>
            </div>
            <div className="col-lg-6"
            // style={{ minHeight: "500px", }}
            >

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.776422283723!2d77.58121367597394!3d12.922086487388778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae159fe670b09b%3A0xc6b798d8e0cbfdcd!2sInvenTech%20Info%20Solutions%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1693304532232!5m2!1sen!2sin" className="map" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
          </div>
        </div>
      </div>

      <Modal open={bangaloreModal} footer={false} onCancel={() => setBangaloreModal(false)} style={{
        top: 140,
      }}
        width={1000} height={800}
      >
        <div className="row g-5">
          <div className="col-lg-5 " >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
              <h3>BANGALORE ADDRESS</h3>
              <p style={{ fontSize: "16px" }}>#296/D/2/56, 2nd Floor,<br /> 9th Main Road, 5th Block, Jayanagar,<br /> Bangalore. Karnataka,<br /> India - 560 011</p>
            </div>
          </div>
          <div className="col-lg-7 " >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.776422283723!2d77.58121367597394!3d12.922086487388778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae159fe670b09b%3A0xc6b798d8e0cbfdcd!2sInvenTech%20Info%20Solutions%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1693304532232!5m2!1sen!2sin" className="bang-map" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </Modal>

      <Modal open={hyderabadModal} footer={false} onCancel={() => setHyderabadModal(false)} style={{
        top: 140,

      }} width={1000} height={800} >
        <div className="row g-5">
          <div className="col-lg-5 " >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
              <h3>HYDERABAD ADDRESS</h3>
              <p style={{ fontSize: "16px" }}> Inventech Info Solutions<br /> Nheights Building, Awfis,<br /> 4th Floor Opp Tech Mahindra SEZ,  <br />Hitech City, Hyderabad</p>
            </div>
          </div>
          <div className="col-lg-7 " >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.179531596624!2d78.36813732604557!3d17.451119183446995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93c48ffb8743%3A0x5aa4aa0466861cc1!2sN-Heights%2C%20Siddiq%20Nagar%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1695975480254!5m2!1sen!2sin" className="bang-map" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </Modal>

      <Modal open={usaModal} footer={false} onCancel={() => setUsaModal(false)} style={{
        top: 140,
      }} width={1000} height={800}>
        <div className="row g-5">
          <div className="col-lg-5 " >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
              <h3>USA ADDRESS</h3>
              <p style={{ fontSize: "16px" }}>#222 W Merchandise Mart Plaza #1212,<br /> Chicago, IL 60654, <br />United States</p>
            </div>
          </div>
          <div className="col-lg-7 " >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.2360985716823!2d-87.63752342322879!3d41.88777927124076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cb6e328686d%3A0x43f86a8214e286bb!2sThe%20Merchandise%20Mart!5e0!3m2!1sen!2sin!4v1694688606623!5m2!1sen!2sin" className="bang-map" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </Modal>


      <Footer />
      <a
        href="#"
        class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i class="bi bi-arrow-up"></i>
      </a>
    </>
  );
};

