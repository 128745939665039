import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import talent from "../../img/Young-creative-people-looking-for-new-ideas-and-projects-800x457.jpg"
import staff1 from "../../img/staffing.png"
import sol from "../../img/sol.jpg"
import staff2 from "../../img/staff-768x768.png"
import funnel from "../../img/funnel-768x803.png"
import Footer from '../../Components/Footer/Footer'
export default function Solutions() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Solution</h1>
                            <p href="" class="h5 text-white">"No technology is "better" or "worse" unless you answer the question "for what purpose?""</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h4 className="mb-0">InvenTech Solutions supports a wide array of Fortune companies as well as small to medium size businesses.  Our long-term growth and accompanying expansion of our service offering have remained based on our original cornerstone: i.e. to deliver expertise through right solution for client by accurate business assessment consulting and thus help our clients optimize their Business need & IT initiatives
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={sol} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">TECHNOLOGY CONSULTING</h1>
                            </div>
                            <p className="mb-4">
                            InvenTech Solutions supports a wide array of Fortune companies as well as small to medium size businesses.  Our long-term growth and accompanying expansion of our service offering have remained based on our original cornerstone: i.e. to deliver expertise through right solution for client by accurate business assessment consulting and thus help our clients optimize their Business need & IT initiatives 
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
                        <h5 className="fw-bold text-primary text-uppercase">Techonologies We use</h5>
                        <h1 className="mb-0">Our experienced team of developers has been helping leading companies across industries with their projects. </h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-shield-alt text-white"></i>
                                </div>
                                <h4 className="mb-3">IT Outsourcing</h4>
                                <p className="m-0">Planning, Designing, Defining, building, Testing, Deployment, Maintenance</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </div>
                                <h4 className="mb-3">Data Analytics</h4>
                                <p className="m-0">Data Aggregation, Data Engineering, Business Intelligence & Reporting, Contextual, Intelligence, Advanced Analytics, Marketing, Analytics</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-code text-white"></i>
                                </div>
                                <h4 className="mb-3">Software Development</h4>
                                <p className="m-0">Customization, Product Development, Feature Development, Software maintenance Support & Maintenance</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fab fa-android text-white"></i>
                                </div>
                                <h4 className="mb-3">UI & UX</h4>
                                <p className="m-0">UI services Mobile App Web Design Consulting</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-search text-white"></i>
                                </div>
                                <h4 className="mb-3">Cyber security</h4>
                                <p className="m-0">Web Application, Product, Network, Operational, Physical, Security Code, Review</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fab fa-android text-white"></i>
                                </div>
                                <h4 className="mb-3">QA & testing</h4>
                                <p className="m-0">Functional, Compatibility, Localization, Performance, Usability, Accessibility, Security</p>
                                {/* <a className="btn btn-lg btn-primary rounded" href="">
                                <i className="bi bi-arrow-right"></i>
                            </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={talent} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Talent Solution</h1>
                            </div>
                            <p className="mb-4">
                                One sure way to enable a company to grow and achieve your goals is to have the “right people in the right seats on the bus and the wrong people off the bus” a quote made famous by organizational guru and author Jim Collins in the early 2000’s. As old as this news is, bad hires still represent one of the most vexing challenges for business leaders.
                            </p>
                            <p className="mb-4">
                                Global economics proves to us on a daily basis the organizational need for ‘fore-most’ and ‘leading’ talent in order to succeed in increasingly complex and competitive global markets. In order to achieve the ‘best’ possible result for organizations, developing the right talent is as much a necessity as hiring and retaining employees.One of the most acute challenges facing businesses today is an ultra- competitive talent landscape. Though it’s not the only challenge organizations are facing, it’s one of the most important and most recognized — not just among human resources and talent acquisition leaders, but CEOs.
                            </p>
                            <p className="mb-4">
                                The highly committed and experienced professionals at InvenTech Global will help manage the talent very effectively which fuels the growth of the organization.
                            </p>

                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">Finding the Right Employee For The Right Employer.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={staff1} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Staffing Solution</h1>
                            </div>
                            <p className="mb-4">
                                We act as a conduit between the organizations and the job seekers. We offer highly technical professionals, by ensuring success for employees as well as the company. Our sphere of operations includes Project Outsourcing, Technical Manpower Outsourcing.
                            </p>
                            <p className="mb-4">
                                INVENTECH Professional Services are available worldwide, with its experienced specialists in the areas of Oracle, PeopleSoft, Quality Assurance, Data Warehousing and e-commerce. Our Global Development Center offers our clients cost effective choices and international support.
                            </p>
                            <p className="mb-4">
                                INVENTECH commitment to employee growth using a mentoring program that updates technical and communication skills, assures our clients that INVENTECH provides the experts to ensure a perfect match to meet your project goals.Our commitment to quality control and Rapid Application Development methodologies allows INVENTECH to staff all projects with our own specialists.
                            </p>
                            <p className="mb-4">
                                The IT functions with the greatest potential for successfully reducing costs through outsourcing are help desk support, desktop support and network operations</p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>ERP and CRM Specialists</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Quality Assurance Specialists</h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Data Warehousing Specialists</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>e-commerce Specialists</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Our Services</h1>
                            </div>
                            <p className="mb-4">
                                <h5 className="mb-1">Perm Staffing</h5>
                                We specialize in Domestic and International recruitments worldwide. We offer suitable Staff Augmentation and as per the requirements of both the organization and the employee. We focus on establishing a mutually beneficial relationship with our clients by addressing their requirements and maximizing their satisfaction.
                            </p>
                            <p className="mb-4">
                                <h5 className="mb-1">Temp Staffing</h5>
                                We are committed to providing effective, efficient, fast, dependable, Technical Resources, on Project Basis. And, of course our Technically Skilled Professionals (TSP) are ready for relocation and to any part of the Globe on Project assignments.
                            </p>
                            <p className="mb-4">
                                <h5 className="mb-1">Outsourcing</h5>
                                We, at INVENTECH, assist professionals to join us and be associated with organizations with congenial work environment and good pay scales. We specialize in Manpower Outsourcing and Project Outsourcing.
                            </p>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={staff2} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={funnel} style={{ objectFit: "cover" }} />
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">InvenTech Sourcing Methodology</h1>
                            </div>
                            <p className="mb-4">
                                At INVENTECH Understanding your organizational needs begins, even before you talk to us. It starts with our Stringent Recruiting and Screening Process. We monitor the Trends in Information Technology Industry to predict Client demand for consultants with specific Technical Capabilities.
                            </p>
                            <p className="mb-4">
                                INVENTECH has a Talent Pool of Data Base that matches your Business and Technological Requirements. Most of our Consultants have Engineering Degrees and at least 3 years of relevant work experience.
                            </p>
                            <p className="mb-4">
                                INVENTECH Stringent Recruitment Methodology encompasses the combination of KNOWLEDGE, SKILL & ATTITUDE.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
