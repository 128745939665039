import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/about.jpg"
import manage from "../../img/cyber.png"
import it from "../../img/IT.png"
import Footer from '../../Components/Footer/Footer'
export default function InfrastructureMgt() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Infrastructure Management</h1>
                            <p href="" class="h5 text-white">"Infrastructure management optimizes resources for seamless operational efficiency."</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">“Infrastructure is much more important than architecture.”
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">IT Infrastructure Management</h1>
                            </div>
                            <p className="mb-4">IT Infrastructure management is key element for today's businesses to be successful.
                                It is very important that dynamic needs of IT infrastructure requirement are aligned with your business needs.
                                For companies to become "Next Generation Business", organizations must deliver on four values: Innovation, Virtualization, Effectiveness and Efficiency.
                            </p>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={manage} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={it} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">What Inventech Offers</h1>
                            </div>
                            <p className="mb-4">
                                Strategic Planning: Leverage our strategic expertise to map out a clear path for your business growth. We identify opportunities, set objectives, and guide you towards success.
                            </p>
                            <p className="mb-4">
                                Market Analysis: Stay ahead of the competition with our comprehensive market analysis. Understand trends, customer behavior, and emerging opportunities.
                            </p>
                            <p className="mb-4">
                                Operational Efficiency: Enhance your operational efficiency with our process optimization strategies. Streamline your workflows for better productivity and cost savings.
                            </p>
                            <p className="mb-4">
                                Change Management: Successfully navigate transitions and changes within your organization. Our change management strategies ensure a smooth transformation.
                            </p>
                            <p className="mb-4">
                                Digital Transformation: Embrace the digital age with confidence. We guide you through the process of adopting new technologies and staying relevant in a digital world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="row g-5">
                        <div class="col-lg-12">
                            <div class="mb-5">
                                <h1 class="mb-4">Inventech Service Offerings Include</h1>
                                <p> <i className="fa fa-check text-primary me-3"></i>
                                    IT Service Desk: Single point contact for entire IT-related issues for our clients.
                                    We offer quick turnaround, robust, efficient and quality solutions with effective cost.</p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Data Center Management Services: We help design and
                                    manage data centers across heterogeneous platforms; we assure quality service with effective cost and faster
                                    response time by eliminating uncertainties in your business objectives.</p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Enterprise Computing: One stop shop, we build manage and
                                    transform you IT needs by providing reliable, efficient and robust solutions enabling your Enterprise
                                    to concentrate on core business objectives.</p>
                                <p> <i className="fa fa-check text-primary me-3"></i>End user Computing: Our End user computing services offers
                                    you a dependable and reliable solution enabling you to concentrate and utilize your valuable time on achieving
                                    your business objectives.</p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Managed Security Solutions: Our Managed Security Solutions
                                    offer wide spectrum of services which enables you meet regulatory standards and data integrity, our security
                                    solution operation is build on Assess, Setup, Monitor, Notify and Automate model.</p>
                                <p> <i className="fa fa-check text-primary me-3"></i>Storage Management: We offer comprehensive and cost-efficient
                                    Storage Management solutions that enable you to meet your storage support and maintenance needs, our storage
                                    solution offering provides comprehensive solution in Storage consolidation and optimization through Monitoring
                                    and management of storage resources.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
