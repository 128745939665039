import React, { useEffect } from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import test from "../../img/test.svg"


export default function Testing_qa() {
  useEffect(()=>{
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
})
  return (
    <>
      <Header />

      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Testing QA</h1>
              <p href="" class="h5 text-white">"Quality is never an accident."</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
            <div className="col-lg-10">
              <div className=" position-relative pb-3 mb-5">
                <h1 className="mb-0">It is always the result of high intention, sincere effort, intelligent direction and skillful execution; it represents the wise choice of many alternatives.
                 <br/> – William A. Foster</h1>
              </div>
              <p className="mb-4">Testing and 
                QA and software testing services aim to ensure that software fully meets requirements and user expectations. InvenTech provides full-range QA services to help our customers deliver high-quality software meeting tight deadlines of frequent releases.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="searchModal" tabindex="-1">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content" style={{ background: "rgba(9, 30, 62, .7)" }}>
            <div class="modal-header border-0">
              <button type="button" class="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body d-flex align-items-center justify-content-center">
              <div class="input-group" style={{ maxWidth: "600px" }}>
                <input type="text" class="form-control bg-transparent border-primary p-3" placeholder="Type search keyword" />
                <button class="btn btn-primary px-4"><i class="bi bi-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-7">
                        <div className="section-title position-relative pb-3 mb-5">
                            <h1 className="mb-0">“We Can Perform Any Testing Type You Need”</h1>
                        </div>
                        <div className="row g-0 mb-3">
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Functional testing</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Compatibility testing</h5>
                            </div>
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Localization testing</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Performance testing</h5>
                            </div>
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Usability testing</h5>
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Accessibility testing</h5>
                            </div>
                            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Security testing</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5" style={{ minHeight: "500px" }}>
                        <div className="position-relative h-100">
                            <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={test} style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <Footer />
      <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

    </>
  )
}
