import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header/Header'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import emp1 from "../../img/Employee/emp1.jpeg"
import emp2 from "../../img/Employee/emp2.jpeg"
import emp3 from "../../img/Employee/emp3.jpeg"
import emp4 from "../../img/Employee/emp4.jpeg"
import emp5 from "../../img/Employee/emp5.jpeg"
import emp6 from "../../img/Employee/emp6.jpeg"
import emp7 from "../../img/Employee/emp7.jpeg"
import emp8 from "../../img/Employee/emp8.jpeg"
import emp9 from "../../img/Employee/emp10.jpeg"
import cric1 from "../../img/cricket/bg.png"
import cric2 from "../../img/cricket/MicrosoftTeams-image (7).png"
import cric3 from "../../img/cricket/MicrosoftTeams-image (8) (1).png"
import chr1 from "../../img/christmas/bg.jpeg"
import chr2 from "../../img/christmas/2.jpeg"
import chr3 from "../../img/christmas/3.jpeg"
import chr4 from "../../img/christmas/4.jpeg"
import chr5 from "../../img/christmas/5.jpeg"
import chr6 from "../../img/christmas/6.jpeg"
import chr7 from "../../img/christmas/7.jpeg"
import chr8 from "../../img/christmas/8.jpeg"
import chr9 from "../../img/christmas/9.jpeg"
import chr10 from "../../img/christmas/10.jpeg"
import chr11 from "../../img/christmas/11.jpeg"

import Footer from '../../Components/Footer/Footer'
import { Button } from 'antd'
import "./Blog.css"
import { CloseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
export default function Blog() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  })

  const diwaliImages = [
    emp1,
    emp2,
    emp3,
    emp4,
    emp5,
    emp6,
    emp7,
    emp8,
    emp9,
  ]

  const cricketImages = [
    cric1,
    cric2,
    cric3,
  ]

  const christmasImages = [
    chr1,
    chr2,
    chr3,
    chr4,
    chr5,
    chr6,
    chr7,
    chr8,
    chr9,
    chr10,
    chr11,
  ]

  const [viewImage, setViewImage] = useState({ img: "", i: 0 })
  const [fix, setFix] = useState(false)
  const revealImage = (img, i) => {
    setViewImage({ img, i })
    setFix(true)
  }

  const handleClose = () => {
    setFix(false)
    setViewImage({ img: "", i: 0 })
  }

  const [imageToBeRendered, setImageToBeRendered] = useState("")

  const ref = useRef(null);
  const handleImage = (d) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
    setImageToBeRendered(d)
  }
  return (
    <>
      <Header fix={fix} />
      {
        viewImage.img &&
        <>
          <div style={{
            width: '100%',
            height: '100vh',
            background: 'black',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            zIndex: "1000"
          }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
              <div style={{ display: "flex", justifyContent: "end", width: "96%" }}>
                <Button onClick={() => handleClose()}>X</Button>
              </div>
              <img src={viewImage.img} style={{ width: "auto", maxWidth: "90%", maxHeight: "90%", margin: "5%" }} />
            </div>
          </div>
        </>
      }

      <div class="container-fluid position-relative p-0">
        <div class="container-fluid bg-primary py-5 bg-header" >
          <div class="row py-5">
            <div class="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 class="display-4 text-white animated zoomIn">Blog</h1>
              <p href="" class="h5 text-white">"BLOG shares insights, informs and inspires readers."</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 blog-content" onClick={() => { handleImage("Cricket") }}>
              <div className="bg-blog-cricket" />
              <p>Cricket </p>
              <p style={{ fontSize: "12px" }}>22<sup>nd</sup> Dec 2022</p>
            </div>
            <div className="col-lg-4 blog-content " onClick={() => { handleImage("diwali") }}>
              <div className="bg-blog-diwali" />
              <p>Diwali Celebration</p>
              <p style={{ fontSize: "12px" }}>14<sup>th</sup> Nov 2023</p>
            </div>
            <div className="col-lg-4 blog-content" onClick={() => { handleImage("Christmas") }}>
              <div className="bg-blog-christmas" />
              <p>Christmas Celebration</p>
              <p style={{ fontSize: "12px" }}>22<sup>nd</sup> Dec 2023</p>
            </div>


          </div>
        </div>
      </div>

      {
        imageToBeRendered === "diwali" &&
        <div ref={ref} className="container-fluid py-5 wow" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-12">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginBottom: "1rem" }}>
                  <Button type="primary" style={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "red" }} onClick={() => setImageToBeRendered(false)}>
                    <CloseOutlined />
                  </Button>
                </div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry columnsCount={3} gutter="10px">
                    {diwaliImages.map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        style={{ width: "100%", display: "block" }}
                        onClick={() => revealImage(image, i)}
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

              </div>
            </div>
          </div>
        </div>
      }

{
        imageToBeRendered === "Cricket" &&
        <div ref={ref} className="container-fluid py-5 wow" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-12">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginBottom: "1rem" }}>
                  <Button type="primary" style={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "red" }} onClick={() => setImageToBeRendered(false)}>
                    <CloseOutlined />
                  </Button>
                </div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry columnsCount={3} gutter="10px">
                    {cricketImages.map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        style={{ width: "100%", display: "block" }}
                        onClick={() => revealImage(image, i)}
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

              </div>
            </div>
          </div>
        </div>
      }

{
        imageToBeRendered === "Christmas" &&
        <div ref={ref} className="container-fluid py-5 wow" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-12">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", marginBottom: "1rem" }}>
                  <Button type="primary" style={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "red" }} onClick={() => setImageToBeRendered(false)}>
                    <CloseOutlined />
                  </Button>
                </div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry columnsCount={3} gutter="10px">
                    {christmasImages.map((image, i) => (
                      <img
                        key={i}
                        src={image}
                        style={{ width: "100%", display: "block" }}
                        onClick={() => revealImage(image, i)}
                      />
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

              </div>
            </div>
          </div>
        </div>
      }


      <Footer />
      <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

    </>
  )
}
