import React, { useEffect } from 'react'
import sde1 from '../../img/sde1.jpg'
import sde2 from '../../img/sde2.jpg'
import Footer from "../../Components/Footer/Footer";
import team1 from "../../img/team-1.jpg"
import team2 from "../../img/team-2.jpg"
import team3 from "../../img/team-3.jpg"
import Header from "../../Components/Header/Header";
import { Link } from "react-router-dom";
import about from "../../img/about.jpg"
export default function Softwaredevelopment() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Software Development</h1>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                    Software Development
                                </h5>
                            </div>
                            <p className="mb-4">
                                    InvenTech’s main focus is on providing software solutions that add business value. We provide end-to-end solutions. We help businesses identify needs, conduct value-chain analysis, propose solutions based on the industry best practices, identify change management strategies, help execute the entire project and provide ongoing application support both in terms of processes or technical review.
                                </p>
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                    SOFTWARE DEVELOPMENT LIFECYCLE OF IT OUTSOURCING TO INVENTECH
                                </h5>
                                
                            </div>
                            <p className="mb-4">
                                    We employ best practices and development methodologies for building effective software development processes and solutions in a structured and systematic way.
                                </p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Planning
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                       Designing
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Defining
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Building
                                    </h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Testing
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>Deployment
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>Maintainance
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img
                                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                                    data-wow-delay="0.9s"
                                    src={sde1}
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        
                        <div className="col-lg-6" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img
                                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                                    data-wow-delay="0.9s"
                                    src={sde2}
                                    style={{ objectFit: "cover" }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                Software Development Engagement Management 
                                </h5>
                            </div>
                            <p className="mb-4">
                            Engagement Management is the art of finding and maintaining a balance between flexibility and control. A project requires flexibility to seize unexpected opportunities and for creative problems solving; it needs control to stay focused on the project’s goals and objectives. Engagement Management is also the science of applying automated tools to well-defined and well-understood development processes. Adapting the management style, tool set, methodology and process to the project team, management team and client culture is a necessary ingredient for success.
                                </p>
                            <div className="section-title position-relative pb-3 mb-3">
                                <h5 className="fw-bold text-primary text-uppercase">
                                InvenTech Offered Collaboration Services for Your Software Development
                                </h5>
                            </div>
                            
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Custom Development
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Product Development
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Outsourced 
                                    </h5>
                                    
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>
                                        Team Augmentation
                                    </h5>
                                    <h5 className="mb-3">
                                        <i className="fa fa-check text-primary me-3"></i>Support & Maintainance
                                    </h5>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <a
                href="#"
                class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
            >
                <i class="bi bi-arrow-up"></i>
            </a>
        </>
    )
}
