import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import { Link } from 'react-router-dom'
import about from "../../img/data.jpeg"
import Footer from '../../Components/Footer/Footer'
export default function DataAnalytics() {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    })
    return (
        <>
            <Header />

            <div class="container-fluid position-relative p-0">
                <div class="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: "90px" }}>
                    <div class="row py-5">
                        <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 class="display-4 text-white animated zoomIn">Data Analytics</h1>
                            <p href="" class="h5 text-white">"Data analytics uncovers insights, driving informed strategic decisions."</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                        <div className="col-lg-10">
                            <div className=" position-relative pb-3 mb-5">
                                <h1 className="mb-0">“No great marketing decisions have ever been made on qualitative data.”
                                    <br />— John Sculley.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-5" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={about} style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h1 className="mb-0">Data Analytics</h1>
                            </div>
                            <p className="mb-4">We leverage data using our data analytics services and solutions,
                                which enables our clients to make informed decisions to meet their business goals.
                                We create machine learning ML- powered models and frameworks and fine-tune
                                decision-making models by coordinating them with our clients business objectives.
                            </p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>  Data Aggregation</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Data Engineering </h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Advanced Analytics</h5>

                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Business Intelligence & Reporting</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Contextual Intelligence</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Marketing Analytics</h5>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <a href="#" class="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i class="bi bi-arrow-up"></i></a>

        </>
    )
}
