import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';

export default function Footer() {

  const [currentLocation, setCurrentLocation] = useState({});
  const [address,setAddress] =useState("")

  useEffect(() => {
    getLocation();
    if(currentLocation.region == "Haryana"){
      setAddress("#296/D/2/56, 2nd Floor, 9th Main Road, 5th Block, Jayanagar, Bangalore. Karnataka, India - 560 011")
    }else if(currentLocation.country == "USA"){
      setAddress("222 W Merchandise Mart Plaza #1212, Chicago, IL 60654, United States")
    }else if(currentLocation.region == "Hydrabad"){
      setAddress("Phase 2, Jaya Prakash Narayan Nagar, Miyapur, Hyderabad, Telangana 500049")
    }else{
      setAddress("#296/D/2/56, 2nd Floor, 9th Main Road, 5th Block, Jayanagar, Bangalore. Karnataka, India - 560 011")
    }
  }, [])

  const getLocation = async () => {
    // const location = await axios.get('https://ipapi.co/json');
    // setCurrentLocation(location.data)
  }

  return (
    <div>
      {" "}
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="index.html" className="navbar-brand">
                  <h1 className="m-0 text-white">
                    <i className="fa fa-user-tie me-2"></i>Inventech
                  </h1>
                </a>
                <p className="mt-3 mb-4">
                  Established in 2009 by proven executives with strong leadership and deep expertise in the global
                  IT services industry, Inventech is dedicated to offering clients.
                </p>

              </div>
            </div>

            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">{address}</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">contactus@inventechinfo.com</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+91 80-26535959</p>
                    </div>
                  <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-twitter fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="#">
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/about">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      About Us
                    </Link>
                    <Link className="text-light mb-2" to="/testingqa">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Testing QA
                    </Link>
                    <Link className="text-light mb-2" to="/ui-ux">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      UI-UX
                    </Link>
                    <Link className="text-light mb-2" to="/cybersecurity">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Cyber Security
                    </Link>
                    <Link className="text-light" to="/infrastructuremanagement">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Infrastructure Management{" "}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div
                    className="section-title section-title-sm position-relative pb-3 mb-4"
                    style={{ visibility: "hidden" }}
                  >
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link className="text-light mb-2" to="/dataanalytics">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Data Analytics
                    </Link>
                    <Link className="text-light mb-2" to="/itsupport">
                      <i className="bi bi-arrow-right text-primary me-2"></i>It
                      Support
                    </Link>
                    <Link className="text-light mb-2" to="/salesforce">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Sales Force
                    </Link>
                    <Link className="text-light mb-2" to="/solution">
                      <i className="bi bi-arrow-right text-primary me-2"></i>
                      Solution
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid text-white"
        style={{ background: "#061429" }}
      >
        <div className="container text-center">
          <div className="row justify-content-end">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px", letterSpacing: "1px" }}
              >
                <p className="mb-0">
                  Copyright © 2023 All rights reserved by
                  <Link className="text-white border-bottom" to="/about">
                    {" "}InventechInfo
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        href="#"
        className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
      >
        <i className="bi bi-arrow-up"></i>
      </a>
    </div>
  );
}
